import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import SuccessModal from "../../components/successModal";
import { BiErrorCircle } from "react-icons/bi";
import "./styles.scss";

import { GET, POST } from "../../utils/apiCalls";
import API from "../../config/API";
import { login } from "../../redux/slices/userSlice";

import { InputOTP } from "antd-input-otp";

import { Auth } from "../../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import SummaryCard from "./summaryCard";
import GmailLogin from "../loginScreens/gmailLogin";
import React from "react";
import PrefixSelector from "../../components/prefixSelector";
import useDebounce from "../../shared/hook/useDebounce";

function SignupScreen() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [successmodal, setSuccessmodal] = useState(false);

  const [datas, setdatas] = useState<any>({});
  const [verification, setverification] = useState(false);
  const [autho, setautho] = useState<any>(null);
  const [phoneTaken, setPhoneTaken] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [emailTaken, setEmailTaken] = useState(false);
  const debouncePhone = useDebounce(phoneNumber, 300);
  const debounceEmail = useDebounce(emailId, 300);
  const [form] = Form.useForm();
  const LoginPhone = async (values: any) => {
    try {
      setdatas(values);
      setIsLoading(true);
      let recaptchas = await new RecaptchaVerifier(Auth, "recaptcha", {});
      let phone = `${values.code}${values.phone}`;
      let checkPhone: any = await signInWithPhoneNumber(
        Auth,
        phone,
        recaptchas
      );
      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setverification(true);
      } else {
        setError("Something went wrong");
      }
    } catch (err: any) {
      setverification(false);

      if (err?.message?.includes("invalid-phone")) {
        setError("Invalid Phone Number.. Please Try another one");
      } else {
        setError("Somehting went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async (values: any) => {
    try {
      setIsLoading(true);
      let otp = values.otp.join("");
      let verify = await autho.confirm(otp);
      const token = await verify?.user?.getIdToken();
      if (token) {
        Signup(token);
      }
    } catch (err: any) {
      if (err?.message?.includes("code-expired")) {
        setError("Otp has been Expired.");
      } else if (err?.message?.includes("invalid-verification-code")) {
        setError("Invalid OTP. Please check");
      } else {
        setError("Somehting went wrong");
      }
      setIsLoading(false);
    } finally {
    }
  };

  const Signup = async (token: string) => {
    try {
      let url = API.SIGNUP;
      let body = {
        email: datas.email,
        username: datas.username,
        password: datas.password,
        first_name: datas?.firstname,
        last_name: datas?.lastname,
        countrycode: datas?.code,
        idToken: token,
      };
      var signupRes: any = await POST(url, body);
      if (signupRes.status) {
        dispatch(login(signupRes));
        setSuccessmodal(true);
        setTimeout(() => {
          setSuccessmodal(false);
        }, 2000);
      } else {
        setError(signupRes.message);
      }
    } catch (err) {
      setError("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  const checkPhoneNumber = async () => {
    const url = API.USER_CHECK_PHONE + phoneNumber;
    if (phoneNumber?.length > 8) {
      try {
        const response: any = await GET(url, null);
        if (response?.status) {
          setPhoneTaken(response?.data);
        } else {
          setPhoneTaken(false);
        }
      } catch (err) {
        setPhoneTaken(false);
      }
    }
  };
  const isValidEmail = (email: string) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const checkEmailId = async () => {
    const url = API.USER_CHECK_EMAIL + emailId;
    if (isValidEmail(emailId)) {
      try {
        const response: any = await GET(url, null);
        if (response?.status) {
          setEmailTaken(response?.data);
        } else {
          setEmailTaken(false);
        }
      } catch (err) {
        setEmailTaken(false);
      }
    } else {
      setEmailTaken(false);
    }
  };
  useEffect(() => {
    checkPhoneNumber();
  }, [debouncePhone]);
  useEffect(() => {
    checkEmailId();
  }, [debounceEmail]);
  return (
    <div className="Screen-box">
      <br /> <br />
      <h2 className="signupScreen-txt1">Create your account</h2>
      <div className="signupScreen-txt2">
        Please enter the following details to signup to your account
      </div>
      <Container>
        <Row>
          <Col sm={4} xs={12}></Col>
          <Col sm={4} xs={12}>
            <div className="LoginScreen-box1">
              {verification ? (
                <>
                  <SummaryCard data={datas} />
                  <div className="signupScreen-txt2">
                    Enter OTP send to your mobile number
                  </div>
                  <Form onFinish={verifyOtp} form={form}>
                    <Form.Item
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: "Input 6 digit verification code !",
                        },
                      ]}
                    >
                      <InputOTP autoFocus inputType="numeric" length={6} />
                    </Form.Item>
                    {error ? (
                      <div className="signupScreen-errortxt">
                        <BiErrorCircle /> &nbsp;
                        {error}
                      </div>
                    ) : null}
                    <Button
                      block
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                      style={{ height: 45 }}
                    >
                      Verify
                    </Button>
                    {error ? (
                      <Button
                        className="mt-2"
                        block
                        size="large"
                        style={{ height: 40 }}
                        htmlType="submit"
                        onClick={(values: any) => {
                          LoginPhone(datas);
                        }}
                      >
                        {"Resend OTP"}
                      </Button>
                    ) : null}
                  </Form>
                </>
              ) : (
                <>
                  <div className="signupScreen-txt2">
                    Enter your Email and and Phone Number
                  </div>
                  <br />
                  <Form
                    onFinish={LoginPhone}
                    initialValues={{ code: "+91" }}
                    layout="vertical"
                  >
                    <Row>
                      <Col sm={6} xs={6}>
                        <Form.Item
                          name={"firstname"}
                          rules={[
                            {
                              required: true,
                              message: "Please enter firstname",
                            },
                            {
                              max: 30,
                              message: "Firstname is too long",
                            },
                          ]}
                        >
                          <Input placeholder="First Name" size="large" />
                        </Form.Item>
                      </Col>
                      <Col sm={6} xs={6}>
                        <Form.Item
                          name={"lastname"}
                          rules={[
                            {
                              required: true,
                              message: "Please enter lastname",
                            },
                            {
                              max: 30,
                              message: "Lastname is too long",
                            },
                          ]}
                        >
                          <Input placeholder="Last Name" size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    {phoneTaken ? (
                      <p className="text-danger my-0 py-0">
                        This Phone number is already used
                      </p>
                    ) : null}
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number",
                        },
                        {
                          max: 14,
                          message: "Phone Number is Invalid",
                        },
                        {
                          min: 8,
                          message: "Please enter a valid phone number",
                        },

                        () => ({
                          validator(_, value) {
                            if (phoneTaken) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve("Phone Number available");
                          },
                        }),
                      ]}
                    >
                      <Input
                        addonBefore={<PrefixSelector />}
                        style={{ width: "100%" }}
                        size="large"
                        placeholder="Enter Phone Number"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onKeyPress={(e) => {
                          const isNumber = /^[0-9]*$/;
                          if (!isNumber.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(ph) => setPhoneNumber(ph?.target?.value)}
                      />
                    </Form.Item>

                    {emailTaken ? (
                      <p className="text-danger my-0 py-0">
                        This Email Id is already used
                      </p>
                    ) : null}
                    <Form.Item
                      name={"email"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email id",
                        },
                        {
                          type: "email",
                          message: "Please enter valid email id",
                        },
                        {
                          max: 60,
                          message: "Email id is Invalid",
                        },
                        () => ({
                          validator(_, value) {
                            if (emailTaken) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve("EmailId available");
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Enter Email Address"
                        size="large"
                        onChange={(em) => setEmailId(em?.target?.value)}
                      />
                    </Form.Item>

                    <Form.Item
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          min: 8,
                          message: "Password must be minimum 8 characters.",
                        },
                        {
                          max: 16,
                          message: "Password is too long",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Password"
                      />
                    </Form.Item>
                    <Form.Item
                      name={"confirm"}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The new password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                      dependencies={["password"]}
                      hasFeedback
                    >
                      <Input.Password
                        size="large"
                        placeholder="Confirm Password"
                      />
                    </Form.Item>
                    {error ? (
                      <div className="signupScreen-errortxt">
                        <BiErrorCircle /> &nbsp;
                        {error}
                      </div>
                    ) : null}
                    <div id="recaptcha"></div>
                    <Button
                      block
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                      style={{ height: 45 }}
                    >
                      Create Account
                    </Button>
                  </Form>
                </>
              )}
              <br />
              <GmailLogin
                closeModal={() => setSuccessmodal(false)}
                openModal={() => setSuccessmodal(true)}
              />
              <br />
              <div
                className="signupScreen-txt4"
                onClick={() => navigation("/login")}
              >
                Already have an account?{" "}
                <span className="signupScreen-txt5">Login</span>
              </div>
            </div>
          </Col>
          <Col sm={4} xs={12}></Col>
        </Row>
      </Container>
      <br />
      <br />
      {successmodal ? (
        <SuccessModal
          visible={successmodal}
          onCancel={() => setSuccessmodal(false)}
          title="Success"
          body="Congartulation Account created successfully"
          onButtonClick={() => setSuccessmodal(false)}
          buttonText="Start Purchasing"
        />
      ) : null}
    </div>
  );
}
export default SignupScreen;

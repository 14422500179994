import "./App.scss";
import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { ConfigProvider, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { BroadcastChannel } from "broadcast-channel";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import API from "./config/API";

import FloatingButton from "./components/floatingButton";
import ProtectedRoute from "./utils/protectedRoute";
import ProtectedRouteAdmin from "./utils/protectedRouteAdmin";
import Header from "./components/header";
import Footer from "./components/footer";

import HomeSCreen from "./screens/homeScreen";
import ForgottPassword from "./screens/forgottPassword";
import SignupScreen from "./screens/signupScreen";
import SellerRegister from "./screens/sellerRegister";
import CorporateSeller from "./screens/sellerRegister/corporate";
import IndividualSeller from "./screens/sellerRegister/individual";
import LoginScreen from "./screens/loginScreens";
import ProductPage from "./screens/productScreen";
import ProductByCategory from "./screens/productByCat";
import ProductSearch from "./screens/productSearch";
import StoreHome from "./screens/StoreHome";
import ViewInvoice from "./screens/ViewInvoice";
import IndividualInfo from "./screens/sellerRegister/individual/individualInfo";
import StoreRedirectScreen from "./screens/storeDetails";
import SearchByStore from "./screens/searchStore";
import EmailVerify from "./screens/verifyMail";
import ResetPassword from "./screens/resetPassword";
import AccountDeactivation from "./screens/deactivateAccount";

import deliveryRestricted from "./screens/deliveryRestricted";
import Faq from "./screens/faq";
import ContactUs from "./screens/ContactUs";
import Privacy from "./screens/PrivacyAndPolicy";
import Terms from "./screens/termsConditions";
import Cancellation from "./screens/cancellation";

import ProfileScreen from "./screens/profileScreen";
import CartScreen from "./screens/cartScreen";
import Checkout from "./screens/checkout";
import CheckoutSuccess from "./screens/checkoutSuccess";

import Admin from "./admin/route";
import ViewAllProducts from "./screens/viewAllProducts";
import ExploreAll from "./screens/explore-all";
import AccebilityStatement from "./screens/accessibility";
import { clearReduxData } from "./utils/redux.util";

function App() {
  const isLogin = useSelector((state: any) => state.User?.auth);
  const Role = useSelector((state: any) => state.User?.user?.data?.role ?? "");
  const Direction = useSelector((state: any) => state.Language.direction);
  let location = useLocation();
  location.pathname.startsWith("/str/");
  const logoutChannel = new BroadcastChannel("logout");
  message.config({ top: 100 });
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    logoutChannel.addEventListener("message", (event) => {
      navigation("/");
      clearReduxData(dispatch);
    });

    return () => {
      logoutChannel.close();
    };
  }, []);
  return (
    <I18nextProvider i18n={i18n}>
      <div dir={Direction}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "DMSans-Medium",
              colorPrimary: API.COLOR,
              lineWidth: 1,
              controlOutlineWidth: 0,
              borderRadius: 4,
            },
            components: {
              Button: {
                fontSize: 16,
                fontWeight: "600",
              },
            },
          }}
        >
          {!location.pathname.startsWith("/str/") ? <Header /> : null}
          <Routes>
            <Route index element={<HomeSCreen />} />;
            <Route path="/" element={<HomeSCreen />} />;
            <Route path="/home" element={<HomeSCreen />} />;
            <Route
              path="/login"
              element={!isLogin ? <LoginScreen /> : <Navigate to="/" replace />}
            />
            <Route
              path="/signup/:type"
              element={
                !isLogin ? <SignupScreen /> : <Navigate to="/" replace />
              }
            />
            ;
            <Route
              path="/forgott"
              element={
                !isLogin ? <ForgottPassword /> : <Navigate to="/" replace />
              }
            />
            <Route path="/seller" element={<SellerRegister />} />;
            <Route path="/slr/corporate" element={<CorporateSeller />} />;
            <Route path="/slr/individual" element={<IndividualSeller />} />;
            <Route path="/slr/individual_info" element={<IndividualInfo />} />
            <Route
              path="/products/c/:category"
              element={<ProductByCategory />}
            />
            <Route path="/product/:name" element={<ProductPage />} />;
            <Route path="/search" element={<ProductSearch />} />;
            <Route path="/store/:store/*" element={<SearchByStore />} />;
            <Route path="/products/view/" element={<ViewAllProducts />} />;
            <Route path="/explore/all" element={<ExploreAll />} />;
            <Route path="/str/:id/*" element={<StoreHome />} />
            <Route path="/delivery-restricted" Component={deliveryRestricted} />
            <Route path="/FAQ_s" Component={Faq} />
            <Route path="/contact_us" Component={ContactUs} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms_of_service" element={<Terms />} />
            <Route
              path="/accessibility_statement"
              element={<AccebilityStatement />}
            />
            <Route path="/cancellation_return" element={<Cancellation />} />
            <Route path="/reset-password/:token" Component={ResetPassword} />
            <Route path="/deactivate/:token" Component={AccountDeactivation} />
            <Route path="/invoice/:token" Component={ViewInvoice} />
            <Route path="/verify/email/:token" element={<EmailVerify />} />
            <Route path="/auth/check_store" element={<StoreRedirectScreen />} />
            {/*user routes login access needed*/}
            <Route element={<ProtectedRoute isSignedIn={isLogin} />}>
              <Route path="/cart" element={<CartScreen />} />;
              <Route path="/checkout" element={<Checkout />} />;
              <Route
                path="/checkoutsuccess/:type"
                element={<CheckoutSuccess />}
              />
              ;
              <Route path="/profile/*" element={<ProfileScreen />} />;
            </Route>
            {/*admin routes login access needed*/}
            <Route
              element={<ProtectedRouteAdmin isSignedIn={isLogin} role={Role} />}
            >
              <Route path="/auth/*" element={<Admin />} />;
            </Route>
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
          {!location.pathname.startsWith("/str/") &&
          !location.pathname.startsWith("/auth/") ? (
            <Footer />
          ) : null}
          <FloatingButton />
        </ConfigProvider>
      </div>
    </I18nextProvider>
  );
}

export default App;

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PiShoppingCartSimple, PiUserCircle } from "react-icons/pi";
// import Logo from "../../../public/logo.png";
import { useTranslation } from "react-i18next";
import { Badge, Popover } from "antd";
import { IoLocationOutline } from "react-icons/io5";
import LanguageSelector from "./components/languageSelector";
import SearchBar from "./searchBar";
import ProfileMenu from "./components/profileMenu";
import ChooseLocationModal from "./components/locationChooseModal";
import useToggle from "../../shared/hook/useToggle";
import React, { useEffect, useState } from "react";
import { LocationType } from "../../shared/types/types";
import API from "../../config/API";

function DeskTop() {
  const Auth = useSelector((state: any) => state.User);
  const User = useSelector((state: any) => state.User.user);
  const Cart = useSelector((state: any) => state.Cart);
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );
  const [openLocation, toggleLocation] = useToggle(
    LocationDetails.latitude && LocationDetails.longitude ? false : true
  );
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const navigation = useNavigate();

  const { t } = useTranslation();
  const [issharepopovervisible, setissharepopovervisible] = useState(false);
  const OpenLink = (link: any) => {
    if (Auth.auth) {
      navigation(link);
    } else {
      navigation("/login");
    }
  };
  const handlepopovervisiblechange = () => {
    setissharepopovervisible(!issharepopovervisible);
  };

  return (
    <div className="Header-deskBox">
      <div onClick={() => navigation("/")}>
        <img src="/nextmelogo.jpg" className="Header-deskLogo" alt="logo" />
      </div>
      <div style={{ margin: 20 }} />
      {Settings?.isLocation == true ? (
        <div
          className="Header-desk-menu Header-deskactive"
          onClick={() => toggleLocation(true)}
        >
          <div>
            <IoLocationOutline size={25} color="#000" />
          </div>
          <div className="Header-location">
            {LocationDetails.latitude
              ? LocationDetails.full_address
                ? LocationDetails?.full_address
                : LocationDetails.postal_code
              : "Select Location"}
          </div>
        </div>
      ) : null}
      <div style={{ margin: 10 }} />
      <SearchBar />
      <div style={{ margin: 10 }} />
      <div className="Header-desk-menu" onClick={() => OpenLink("/cart")}>
        <div>{t("cart")}</div>
        <div style={{ margin: 4 }} />
        <div className="DesktopHeader">
          <Badge count={Cart?.items?.length} color={API.COLOR}>
            <PiShoppingCartSimple size={25} color="grey" />
          </Badge>
        </div>
      </div>
      <Popover
        placement="bottomRight"
        trigger="click"
        content={<ProfileMenu close={handlepopovervisiblechange} />}
        arrow={false}
        visible={issharepopovervisible}
        onVisibleChange={handlepopovervisiblechange}
      >
        <div
          className={
            Auth.auth
              ? "Header-desk-menu Header-deskactive"
              : "Header-desk-menu"
          }
        >
          <div>
            {User?.data?.first_name
              ? User.data?.first_name
              : User.data?.data?.first_name || t("signin")}
          </div>
          <div style={{ margin: 4 }} />
          {User?.data?.image ? (
            <img
              style={{ marginTop: -4, marginBottom: -4 }}
              src={User?.data?.image}
              className="Header-ProfileImag"
              alt="profile"
            />
          ) : (
            <PiUserCircle size={25} color="grey" />
          )}
        </div>
      </Popover>

      <LanguageSelector />
      <ChooseLocationModal open={openLocation} modalClose={toggleLocation} />
    </div>
  );
}
export default DeskTop;

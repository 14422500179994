import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageHeader from "../components/PageHeader";
import DataTable from "./DataTable";
import API from "../../config/API";
import { useSelector, useStore } from "react-redux";
import { GET } from "../../utils/apiCalls";
import Loading from "../../components/loading";
import NoData from "../../components/noData";
import Search from "antd/es/input/Search";
import { Button, DatePicker, Select, Space, notification } from "antd";
import { GrPowerReset } from "react-icons/gr";
import useDidUpdateEffect from "../../shared/hook/useDidUpdate";
function Orders() {
  const [loading, setLoading] = useState(true);
  const Auth = useSelector((state: any) => state.User?.user?.data);
  const [orders, setOrders] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState({ from: "", to: "" });
  const [orderStatus, setOrderStatus] = useState<string>("");
  const [Notifications, contextHolder] = notification.useNotification();
  const options = [
    { value: "", label: "All Orders" },
    { value: "pending", label: "Pending" },
    { value: "cancelled", label: "Cancelled" },
    { value: "shipped", label: "Shipped" },
    { value: "out_for_delivery", label: "Out For Delivery" },
    { value: "packed", label: "Packed" },
    { value: "delivered", label: "Delivered" },
    { value: "rejected", label: "Rejected" },
    { value: "processing", label: "Processing" },
    { value: "failed", label: "Failed" },
  ];
  const { RangePicker } = DatePicker;
  const pageSize = 12;

  
  const getOrders = async (
    page: number,
    search: string = "",
    from: string = "",
    to: string = ""
  ) => {
    const url =
      API.ORDER_GET_BYSTORE +
      `?order=DESC&page=${page}&take=${pageSize}&orderId=${search}&from=${from}&to=${to}&status=${orderStatus}`;
    setLoading(true);
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setOrders(response?.data);
        setMeta(response?.meta);
      } else {
        Notifications["error"]({
          message: response.message ?? "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };
  useDidUpdateEffect(() => {
    getOrders(1);
  }, [orderStatus]);
  const changePage = async (page: number) => {
    await getOrders(page, search, date.from, date.to);
    setPage(page);
    window.scrollTo(0, 0);
  };
  const onSearch = async (value: string) => {
    if (value) {
      setReset(true);
      setSearch(value);
      setSearching(true);
      await getOrders(1, value);
      setPage(1);
      setSearching(false);
    }
  };
  const getDates = (dates: any, dateString: string[]) => {
    setDate({ from: dateString[0], to: dateString[1] });
    getOrders(1, "", dateString[0], dateString[1]);
    setPage(1);
  };
  useEffect(() => {
    getOrders(1);
  }, []);
  return (
    <div>
      {contextHolder}
      <PageHeader
        title={`Orders ${!loading ? `(${meta?.itemCount ?? "0"})` : ""}`}
        second={"All orders"}
      >
        <Select
          defaultValue="All Orders"
          style={{ width: 200 }}
          options={options}
          onChange={(v) => {
            setOrderStatus(v);
          }}
        />
        <RangePicker
          style={{ width: "220px" }}
          onChange={(dates, dateString) => getDates(dates, dateString)}
        />
        <Search
          placeholder="Search Order ID"
          allowClear
          enterButton="Search"
          size="middle"
          style={{ width: "300px" }}
          onSearch={onSearch}
          type="number"
          loading={searching}
        />
        {reset ? (
          <Button
            type="primary"
            icon={<GrPowerReset />}
            size={"middle"}
            onClick={() => {
              getOrders(1);
              setReset(false);
              setDate({ from: "", to: "" });
            }}
          />
        ) : null}
      </PageHeader>
      {loading ? (
        <Loading />
      ) : orders.length ? (
        <DataTable
          data={orders}
          meta={meta}
          page={page}
          pageSize={pageSize}
          changePage={changePage}
        />
      ) : (
        <NoData header="No Orders Found" />
      )}
    </div>
  );
}
export default Orders;
